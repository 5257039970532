.ch-code-scroll-content > div {
  transform: translateX(16px) translateY(19px) scale(1) !important;
}

.ch-codeblock,
.ch-codegroup {
  margin-bottom: 40px;
  margin-left: -32px;
  margin-right: -32px;
  box-shadow: none;
}

@media (max-width: 48rem) {
  .ch-codeblock,
  .ch-codegroup {
    margin-left: -16px;
    margin-right: -16px;
  }

  .ch-code-scroll-content > div {
    transform: translateX(0px) translateY(19px) scale(1) !important;
  }
}

table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

table,
tr,
td,
th {
  border: 1px solid #555;
  border-collapse: collapse;
  padding: 4px;
}
